<template>
  <div>
    <v-card
      v-for="(contact, i) in contacts"
      :key="i"
      max-width="400"
      class="mb-4"
    >
      <v-list-item
        three-line
      >
        <v-list-item-content>
          <div
            class="overline mb-4"
          >
            {{ contact.name }}
          </div>

          <v-list-item-title
            class="headline mb-1"
          >
            {{ contact.role }}
          </v-list-item-title>

          <v-list-item-subtitle
            :style="{
              '-webkit-line-clamp': 10,
            }"
          >
            {{ contact.body }}
            <br><br>
            {{ contact.phone }}
            <br>
            <span
              class="d-block mt-1"
            >
              {{ contact.email }}
            </span>
          </v-list-item-subtitle>
        </v-list-item-content>

        <v-list-item-avatar
          size="80"
        >
          <v-img
            :src="`/authors/${contact.img}`"
          />
        </v-list-item-avatar>
      </v-list-item>

      <v-card-actions>
        <v-btn
          text
          :href="`tel:${contact.phone}`"
        >
          <span
            v-translate
            translate-context="Call button"
          >
            Call
          </span>
        </v-btn>

        <v-btn
          text
          target="_blank"
          :href="`mailto:${contact.email}`"
        >
          <span
            v-translate
            translate-context="Email button"
          >
            Email
          </span>
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
  export default {
    computed: {
      contacts() {
        return [
          {
            role: this.$pgettext('MM Contact info (role)', 'Guide'),
            name: 'Mikko Hell',
            body: this.$pgettext('MM Contact info (body)', 'Superuser who knows Mestamaster like the back of his hand!'),
            phone: '+358 44 913 5409',
            email: 'mikko@mestamaster.fi',
            img: 'mikko.jpeg',
          }
        ];
      },
    },
  };
</script>
